<template>
  <ConstructionAlert :title="$t('message.legal')" />
</template>

<script>
import ConstructionAlert from "@/components/ConstructionAlert.vue";
export default {
  name: "Legal",
  components: {
    ConstructionAlert,
  },
};
</script>

<style>
</style>